* {
  font-family: 'Death Star', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&family=VT323&display=swap');
@import url('https://fonts.cdnfonts.com/css/visitor');
@import url('https://fonts.cdnfonts.com/css/death-star');

li{
  font-family: "JetBrains Mono", monospace;
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 2000000000000;
  position: fixed;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-flow: column nowrap;
  
  /* Adjust the height based on your layout */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #02ace0;
}

@media screen and (max-width: 499px) {

  .hidden{
    display: none;
  }
  .loadTxt {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
   color: #f0f2f34d;
    /* Red color for text */
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
    font-family: "JetBrains Mono", monospace;
  
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #12a0ff;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  
    #sticky-button {
      position: sticky;
      visibility: hidden;
      position: sticky;
      bottom: 7%;
      margin-left: 80%;
      margin-top: -5%;
    
      cursor: pointer;
      z-index: 1;
      z-index: 100000;
      transition: transform .4s;
    }
  
    .stickyBtn2:hover {
      transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 14px;
    padding: 10px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
    width: 90%;
    justify-content: space-evenly;
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
    text-align: center;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    text-align: left;
  }
  
  .nameAndIcon img{
    width: 25px;
    height: 25px;
    margin-top: -5px;
  
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #143b5552;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #5dce55;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #1c85fc solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 12px;
    margin-right: 12px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 45%;
    height: 45%;
    margin-top: -20%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 45%;
    height: 45%;
    margin-top: -20%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 26px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 32px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 42px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 15px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 60px;
    padding-bottom: 30px;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .boxWrap2Mint {
    padding-top: 60px;
    padding-bottom: 30px;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 70px;
    padding-bottom: 70px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 80%;
    animation: floating 8s ease-in-out infinite;
    margin-right: auto;
    margin-left: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 35px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 35px;
    margin-bottom: 20px;
    text-align: center;

  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 35px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 35px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;

  }

  .conT3 {
    font-size: 35px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 35px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 15px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: justify;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 15px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 14px;

  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 7%;
  }

  .box1 img {
    border: black 2px solid;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px;

  }

  .box1 {
    background-color: #202020;
    padding: 30px;
     border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 25px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 14px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 14px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;

  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 18px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 8px;
    letter-spacing: 8px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 10px;
    text-align: left;
    font-size: 15px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 25px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
    font-size: 14px;
  }

  .iconsMain {
    display: flex;
    justify-content: center;
    width: 35%;
     margin-left: auto;
     margin-right: auto;
     margin-top: 30px;
  }

  .iconsMain img {
    width: 120px;
    height: 120px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .stickyBtn2 {
 
  }  

  .loadTxt {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
   color: #f0f2f34d;
    /* Red color for text */
    text-align: center;
    margin-top: 20px;
    letter-spacing: 5px;
    font-family: "JetBrains Mono", monospace;
  
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #12a0ff;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
     margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .stickyBtn2:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 14px;
    padding: 10px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
    width: 90%;
    justify-content: space-evenly;
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
    text-align: center;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    text-align: left;
  }
  
  .nameAndIcon img{
    width: 25px;
    height: 25px;
    margin-top: -5px;
  
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #143b5552;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #5dce55;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #1c85fc solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 12px;
    margin-right: 12px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 40%;
    height: 40%;
    margin-top: -20%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 40%;
    height: 40%;
    margin-top: -20%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 26px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 45px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 60px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 15px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 60px;
    padding-bottom: 30px;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .boxWrap2Mint {
    padding-top: 60px;
    padding-bottom: 30px;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 70px;
    padding-bottom: 70px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 80%;
    animation: floating 8s ease-in-out infinite;
    margin-right: auto;
    margin-left: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 40px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;

  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 40px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 40px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;

  }

  .conT3 {
    font-size: 40px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 40px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 15px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 15px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 14px;

  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 7%;
  }

  .box1 img {
    border: black 2px solid;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 300px;

  }

  .box1 {
    background-color: #202020;
    padding: 30px;
     border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 16px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;

  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 18px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 8px;
    letter-spacing: 8px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 10px;
    text-align: left;
    font-size: 15px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 25px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
    font-size: 14px;
  }

  .iconsMain {
    display: flex;
    justify-content: center;
    width: 35%;
     margin-left: auto;
     margin-right: auto;
     margin-top: 30px;
  }

  .iconsMain img {
    width: 150px;
    height: 150px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
 
.loadTxt {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
 color: #f0f2f34d;
  /* Red color for text */
  text-align: center;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: "JetBrains Mono", monospace;

}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #12a0ff;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
} 
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 14px;
    padding: 10px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
  
  .nameAndIcon img{
    width: 25px;
    height: 25px;
    margin-top: -5px;
  
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #143b5552;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #5dce55;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #1c85fc solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 12px;
    margin-right: 12px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 26px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 55px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 60px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 16px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 7px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 80%;
    animation: floating 8s ease-in-out infinite;
    margin-right: auto;
    margin-left: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 70px;
    margin-bottom: 20px;
    text-align: center;

  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 70px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;

  }

  .conT3 {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 70px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }

  .storyCon {
    font-size: 18px;
    width: 100%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 7%;
  }

  .box1 img {
    border: black 2px solid;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 300px;

  }

  .box1 {
    background-color: #202020;
    padding: 30px;
     border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;

  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 150px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 30px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
  }

  .iconsMain {
    display: flex;
    justify-content: center;
    width: 35%;
     margin-left: auto;
     margin-right: auto;
     margin-top: 30px;
  }

  .iconsMain img {
    width: 170px;
    height: 170px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
.loadTxt {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
 color: #f0f2f34d;
  /* Red color for text */
  text-align: center;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: "JetBrains Mono", monospace;

}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #12a0ff;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 16px;
    padding: 10px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
  
  .nameAndIcon img{
    width: 30px;
    height: 30px;
    margin-top: -5px;
  
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 12px;
    margin-right: 12px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 30px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 75px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 90px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 18px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 1%;
    margin-bottom: 2%;
    letter-spacing: 10px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    animation: floating 8s ease-in-out infinite;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 70px;
    margin-bottom: 20px;
  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 70px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;

  }

  .conT3 {
    font-size: 70px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 70px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 200px;
    height: 200px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 16px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;

  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 20%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 30px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
  }

  .iconsMain {
    display: flex;
    justify-content: space-evenly;
    width: 35%;
    margin-top: -290px;
    margin-left: 5%;
  }

  .iconsMain img {
    width: 150px;
    height: 150px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  
.loadTxt {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
 color: #f0f2f34d;
  /* Red color for text */
  text-align: center;
  margin-top: 20px;
  letter-spacing: 5px;
  font-family: "JetBrains Mono", monospace;

}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #12a0ff;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 16px;
    padding: 10px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div {
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
  
  .nameAndIcon img {
    width: 30px;
    height: 30px;
    margin-top: -5px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 10px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .socialIcon img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
    margin-right: 8px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
     justify-content: center;
     overflow: hidden;
     contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 30px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 80px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 100px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 18px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 1%;
    margin-bottom: 2%;
    letter-spacing: 10px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    animation: floating 8s ease-in-out infinite;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 80px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 80px;
    margin-bottom: 20px;
  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 80px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 80px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;

  }

  .conT3 {
    font-size: 80px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 80px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
  }

  .storyCon {
    font-size: 18px;
    width: 48%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 20px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border: black 2px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 30px;
    border: black 2px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 50px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 18px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 50px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 18px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;

  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 3spx;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 60px;
    color: rgb(255, 255, 255);
    text-align: center;

    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 160px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 30px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
  }

  .iconsMain {
    display: flex;
    justify-content: space-evenly;
    width: 35%;
    margin-top: -290px;
    margin-left: 5%;
  }

  .iconsMain img {
    width: 220px;
    height: 220px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 1920px) {

  .loadTxt {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
   color: #f0f2f34d;
    /* Red color for text */
    text-align: center;
    margin-top: 30px;
    letter-spacing: 6px;
    font-family: "JetBrains Mono", monospace;
  
  }
  
  .loader {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: inline-block;
    border-top: 6px solid #FFF;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border-left: 6px solid #12a0ff;
    border-bottom: 6px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 22px;
    padding: 15px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 15px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
  
  .nameAndIcon img{
    width: 40px;
    height: 40px;
    margin-top: -5px;
  
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 26px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 30px;
    margin-right: 30px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 26px;
    padding-left: 35px;
    padding-right: 35px;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 15px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
   }

  .socialIcon img {
    width: 42px;
    height: 42px;
    margin-left: 12px;
    margin-right: 12px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 40px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 115px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 140px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 26px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 1%;
    margin-bottom: 2%;
    letter-spacing: 15px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    animation: floating 8s ease-in-out infinite;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 110px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 110px;
    margin-bottom: 20px;
  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 110px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 110px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;

  }

  .conT3 {
    font-size: 110px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 110px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
  }

  .storyCon {
    font-size: 26px;
    width: 48%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 40px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 450px;
    height: 450px;
    border: black 4px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 50px;
    border: black 4px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 15px -15px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 4px;
    font-size: 70px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 26px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 60px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 26px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 15px;
    letter-spacing: 15px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 30px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 26px;
    color: rgb(0, 0, 0);
    padding: 2.8rem;
    margin-bottom: 4.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 40px;
    text-align: left;
    font-size: 28px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 80px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 210px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 40px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 3%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 45px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
    font-size: 24px;
  }

  .iconsMain {
    display: flex;
    justify-content: space-evenly;
    width: 35%;
    margin-top: -430px;
    margin-left: 5%;
  }

  .iconsMain img {
    width: 300px;
    height: 300px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .loadTxt {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
   color: #f0f2f34d;
    /* Red color for text */
    text-align: center;
    margin-top: 40px;
    letter-spacing: 8px;
    font-family: "JetBrains Mono", monospace;
  
  }
  
  .loader {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    display: inline-block;
    border-top: 10px solid #FFF;
    border-right: 10px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border-left: 10px solid #12a0ff;
    border-bottom: 10px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 32px;
    padding: 20px;
    background-color: #1d1d1d;
    border-radius: 50px;
    margin: 20px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
  }
  
  .nameAndIcon img{
    width: 50px;
    height: 50px;
    margin-top: -5px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 34px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 40px;
    margin-right: 40px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 22px;
    font-size: 34px;
    padding-left: 55px;
    padding-right: 55px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 25px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
   }

  .socialIcon img {
    width: 55px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 58px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 150px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 180px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 34px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 1%;
    margin-bottom: 2%;
    letter-spacing: 20px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    animation: floating 8s ease-in-out infinite;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 160px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 160px;
    margin-bottom: 20px;
  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 160px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 160px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;

  }

  .conT3 {
    font-size: 160px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 160px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
  }

  .storyCon {
    font-size: 34px;
    width: 48%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 50px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 550px;
    height: 550px;
    border: black 6px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 70px;
    border: black 6px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 20px -20px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 4px;
    font-size: 90px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 34px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 60px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 95px;
    height: 95px;
    margin-left: 35px;
    margin-right: 35px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 34px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 40px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 6px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 20px;
    letter-spacing: 20px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 30px;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 34px;
    color: rgb(0, 0, 0);
    padding: 3.8rem;
    margin-bottom: 5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 50px;
    text-align: left;
    font-size: 38px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }


  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 110px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 280px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 58px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
    font-size: 30px;
  }

  .iconsMain {
    display: flex;
    justify-content: space-evenly;
    width: 35%;
    margin-top: -520px;
    margin-left: 5%;
  }

  .iconsMain img {
    width: 400px;
    height: 400px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

@media screen and (min-width: 3840px) {
  
  .loadTxt {
    text-align: center;
    font-size: 2.5em;
    font-weight: bold;
   color: #f0f2f34d;
    /* Red color for text */
    text-align: center;
    margin-top: 60px;
    letter-spacing: 8px;
    font-family: "JetBrains Mono", monospace;
  
  }
  
  .loader {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-block;
    border-top: 10px solid #FFF;
    border-right: 10px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border-left: 10px solid #12a0ff;
    border-bottom: 10px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  .mintChains{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: 20px;
  }
  
  .nameAndIcon{
    display: flex;
    color: rgba(211, 208, 252, 0.555);
    font-size: 42px;
    padding: 30px;
    background-color: #1d1d1d;
    border-radius: 100px;
    margin: 30px;
    cursor: pointer;
    border: 1px solid hsla(209, 100%, 76%, 0.185);
  }
  
  .nameAndIcon:hover{
    background-color: #2b2b2b;
  }
  
  .nameAndIcon div{
    font-family: "JetBrains Mono", monospace;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
  }
  
  .nameAndIcon img{
    width: 60px;
    height: 60px;
    margin-top: -5px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000000;
    z-index: 1000000;
    padding-top: 70px;
    padding-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
    width: 33.33vw;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 52px;
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-family: "JetBrains Mono", monospace;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #02ace0;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33vw;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 50px;
    margin-right: 50px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 35px;
    font-size: 52px;
    padding-left: 85px;
    padding-right: 85px;
    border-width: 10px;
    border-style: solid;
    border-image: linear-gradient(to right, #308ff6, #506ef5);
    border-image-slice: 1;
    color: white;
    font-family: "JetBrains Mono", monospace;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    margin-left: 35px;
  }

  .connectBtn:hover {
    color: #02ace0;
  }

  .socialIcon {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
   }

  .socialIcon img {
    width: 82px;
    height: 82px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .6s;
  }

  .socialIcon img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/d06d5dda-5c8f-4e5e-97a3-88fa581c1366.jpg') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 15vh;

  }

  .rockets {
    display: flex;
    justify-content: space-between;
  }

  .rocketsImg1 {
    width: 30%;
    height: 30%;
    margin-top: -25%;
    animation: floating 8s ease-in-out infinite;
  }

  .rocketsImg2 {
    width: 30%;
    height: 30%;
    margin-top: -22%;
    animation: floating 9s ease-in-out infinite;
  }

  .logoDiv {
    width: 33.33vw;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 78px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    font-size: 220px;
    color: white;
    text-align: center;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
    line-height: 270px;
    justify-content: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.37), 0px -5px 35px rgba(255, 255, 255, 0.397);
  }

  .subH {
    color: #ffffff;
    text-align: center;
    font-family: "JetBrains Mono", monospace;
    font-size: 52px;
    text-shadow: 0 0 10px #81f5fd;
    margin-top: 1%;
    margin-bottom: 2%;
    letter-spacing: 30px;
    text-transform: uppercase;
  }

  .button-29 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 20px;
  }

  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  .button-29:active {
    box-shadow: #1d1e29 0 3px 7px inset;
    transform: translateY(2px);
  }

  .hl {
    color: #58dfe4;

  }

  .download {
    width: 40px;
    animation: arrow_down_animation 2s ease-in-out infinite;

  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;

    }

  }

  .downloadDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
  }


  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Death Star', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 5%;
    margin-bottom: 5%;
    background: rgb(13, 34, 43);
    background: linear-gradient(180deg, rgba(13, 34, 43, 1) 0%, rgba(1, 15, 22, 1) 50%, rgba(0, 31, 34, 1) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.637) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Story-about {
    padding-top: 5%;
    padding-bottom: 3%;
    background: rgb(0, 4, 6);
    background: linear-gradient(180deg, rgba(0, 4, 6, 1) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }

  .boxWrapTeam {
    margin-top: 5%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 3%;
    background-color: black;
  }

  .boxWrap2Footer {
    margin-top: 15%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.896796218487395) 0%, rgba(0, 0, 0, 0.36738445378151263) 50%, rgba(0, 0, 0, 0.8995973389355743) 100%), url('./assets/2709894.jpg') no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .faqBg {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/bd65173a-2138-47d9-b350-c8307b91dc65.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    animation: floating 8s ease-in-out infinite;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  @keyframes floating {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .conT {
    font-size: 200px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    line-height: 200px;
    margin-bottom: 40px;
  }

  .hl2 {
    font-family: 'Death Star', sans-serif;
    color: #81f5fd;

  }

  .conT2 {
    font-size: 200px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: center;
    line-height: 200px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;

  }

  .conT3 {
    font-size: 200px;
    color: white;
    font-family: 'Death Star', sans-serif;
    z-index: 1;
    text-align: left;
    line-height: 200px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: 'Death Star', sans-serif;
  }

  .storyCon {
    font-size: 52px;
    width: 48%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-bottom: 65px;
    text-align: left;
    font-family: "JetBrains Mono", monospace;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 25%;
    margin-left: 27%;
    margin-top: -29%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 80px;
    display: flex;
    border: 5px gray dashed;
    padding: 50px;
  }


  .mintCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    font-family: "JetBrains Mono", monospace;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    margin-top: -30px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: 70px;
    height: 70px;
    border: none;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: -70px;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 150px;
    font-family: 'Death Star', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 7%;
  }

  .box1 img {
    width: 900px;
    height: 900px;
    border: black 8px solid;
  }

  .box1 {
    background-color: #202020;
    padding: 80px;
    border: black 8px solid;
    transition: transform .2s ease, box-shadow .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    transform: rotate(-5deg);
    box-shadow: 25px -25px rgb(0, 140, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Death Star', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 4px;
    font-size: 130px;
    color: rgb(165, 217, 252);
  }

  .name2 {
    font-size: 52px;
    color: whitesmoke;
    font-family: "JetBrains Mono", monospace;

  }

  .subTeam {
    text-align: center;
    margin-top: 60px;
    color: #aaaaaa98;
    font-size: 20px;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2%;
  }

  .icons img {
    width: 140px;
    height: 140px;
    margin-left: 45px;
    margin-right: 45px;
    border-radius: 100%;
    transition: transform .6s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #icon1 {
    animation: floating 8s ease-in-out infinite;
  }

  #icon2 {
    animation: floating 7s ease-in-out infinite;
  }

  #icon3 {
    animation: floating 6s ease-in-out infinite;
  }


  #icon4 {
    animation: floating 6s ease-in-out infinite;
  }



  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(128, 210, 243);
    font-size: 45px;
    text-shadow: 0 0 10px #1acaff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    font-family: "JetBrains Mono", monospace;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch {
    font-size: 60px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .footerT {
    font-family: 'Death Star', sans-serif;
    color: white;
    font-size: 80px;
    background: -webkit-linear-gradient(#62aeff, #2a50fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: fit-content;
    border: 6px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 30px;
    letter-spacing: 40px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 30px;
    border-width: 8px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.116);
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    margin-top: -27px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .smallL {
    font-size: 25px;
    letter-spacing: 0px;
    color: #ffffffdc;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #71cdf1;
    font-family: "JetBrains Mono", monospace;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #0366d8;
    box-shadow: 0 0 15px #0479ff;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #0479ff;
    box-shadow: 0 0 15px #04cdff;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #0366d8;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 40px;
    height: 40px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }


  summary {
    font-size: 52px;
    color: rgb(0, 0, 0);
    padding: 5rem;
    margin-bottom: 8rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #0369999a;
    font-weight: 500;
    border-width: 9px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: "JetBrains Mono", monospace;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 7%;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 80px;
    text-align: left;
    font-size: 58px;
    text-shadow: 4px 10px 8px rgba(0, 0, 0, 0.548), 0px -10px 35px rgba(255, 255, 255, 0.3);

  }

  .faq__content p {
    font-family: "JetBrains Mono", monospace;

  }


  #sticky-button {
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .glitch-wrapper2 {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .glitch2 {
    font-size: 170px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    font-family: 'Death Star', sans-serif;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch2::before,
  .glitch2::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch2::before {
    left: 2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch2::after {
    left: -2px;
    text-shadow: -2px 0 #0084ff;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }


  .icons2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
  }

  .icons2 img {
    width: 400px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    opacity: 0.5;
    transition: 0.5s ease-in-out;
  }

  .icons2 img:hover {
    opacity: 1;
  }

  .chainsMain {
    background-color: #7c7c7c62;
    padding: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    margin-top: 5%;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .cHeader {
    color: rgb(189, 189, 189);
    text-align: center;
    font-size: 88px;
  }

  .chainT {
    margin-bottom: 2%;
    font-family: "JetBrains Mono", monospace;
    text-align: center;
    color: rgba(255, 255, 255, 0.219);
    font-size: 50px;
  }

  .iconsMain {
    display: flex;
    justify-content: space-evenly;
    width: 35%;
    margin-top: -960px;
    margin-left: 5%;
  }

  .iconsMain img {
    width: 580px;
    height: 580px;
  }


  #icon1F {
    animation: floating 8s ease-in-out infinite;
    margin-left: -30px;
  }

  #icon2F {
    animation: floating 7s ease-in-out infinite;
    margin-right: -30px;
    rotate: -5deg;

  }

  #icon3F {
    animation: floating 5s ease-in-out infinite;
    margin-left: -20px;
    rotate: -7deg;

  }

  #icon4F {
    animation: floating 6s ease-in-out infinite;
    margin-right: -30px;
    margin-left: -20px;
    rotate: 7deg;

  }
}

.road{
  width: 100%;
}

  
.nameAndIcon div.hoverText::after {
  content: 'AVAX Diamond Woman';
}

.nameAndIcon div.hoverText:hover::after {
  content: 'Coming soon';
  cursor:  wait;
}
  
.nameAndIcon div.hoverText2::after {
  content: 'SOLANA Diamond Man';
}

.nameAndIcon div.hoverText2:hover::after {
  content: 'Coming soon';
  cursor:  wait;
}

.nameAndIcon2 {
  display: none;
}

.nameAndIconContainer:hover .nameAndIcon2 {
  display: block;
}

.nameAndIconContainer:hover .nameAndIcon {
  display: none;
}
